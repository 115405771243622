import { useSearchParams } from "react-router-dom"

import { updateSearchParams } from "@/helpers/url"

import {
  CallReportSearchOptions,
  searchOptionsSchema,
} from "./call-history-schemas"

export function useCallHistorySearch() {
  const [params, setParams] = useSearchParams()

  const searchOptions = searchOptionsSchema.parse({
    date: params.get("date") || undefined,
    statuses: params.getAll("statuses") || [],
    search_query: params.get("search_query") || "",
  })

  const onChange =
    <K extends keyof CallReportSearchOptions>(key: K) =>
    (value: CallReportSearchOptions[K]) => {
      setParams((current) => {
        updateSearchParams(current, key, value)

        return current
      })
    }

  return { searchOptions, onChange }
}
