import { z } from "zod"

import { optionalDateTimeSchema } from "@/helpers/zodSchemas"
import { useQueryAPI } from "@/hooks/query-api"
import { statusMap } from "@/models/cases"

const callReportSchema = z.object({
  group_id: z.string(), // the call report unique ID
  connect_user_id: z.string(),
  last_name: z.string(),
  first_name: z.string(),
  phone_number: z.string(),
  email: z.string(),
  campaign_id: z.string(),
  student_id: z.string(),
  legacy_id: z.number(),
  status: z.nativeEnum(statusMap),
  reported_at: z.string(),
  processing_duration_seconds: z.number().nullable(),
  call_duration_seconds: z.number().nullable(),
  call_attempted: z.boolean(),
  call_type: z.string().nullable(),
  comment: z.string().nullable(),
  agent_info: z.object({
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
  }),
})

export type CallReport = z.infer<typeof callReportSchema>

const historyResponseSchema = z.object({
  data: z.object({
    call_reports: z.array(callReportSchema),
    total: z.number(),
  }),
})

export function useFetchCallHistory({
  date,
  page,
  pageSize,
  search_query,
  statuses,
}: {
  date?: Date
  page: number
  pageSize: number
  search_query?: string
  statuses: string[]
}) {
  return useQueryAPI({
    apiEndPoint: "/call-reports/search",
    method: "POST",
    params: {
      page,
      page_size: pageSize,
      search_query,
      ...(date && {
        from_date: getStartDate(date).toISOString(),
        to_date: getEndDate(date).toISOString(),
      }),
    },
    data: {
      statuses,
    },
    schema: historyResponseSchema,
  })
}

function getStartDate(date: Date) {
  const start = new Date(date)
  start.setHours(0, 0, 0, 0)

  return start
}

function getEndDate(date: Date) {
  const end = new Date(date)
  end.setHours(23, 59, 59, 999)

  return end
}

export const searchOptionsSchema = z.object({
  date: optionalDateTimeSchema,
  statuses: z.array(z.string()).default([]),
  search_query: z.string().optional(),
})

export type CallReportSearchOptions = z.infer<typeof searchOptionsSchema>
